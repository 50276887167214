import tableGetters from "../../../templates/table/getters";
import { cloneDeep } from "lodash";

export default {
  ...tableGetters,
  files: state => annotationId => {
    return state.entity[annotationId].files.map(fileId => state.file[fileId]);
  },
  entities: state =>
    state.ids.map(annotationId => {
      let annotation = cloneDeep(state.entity[annotationId]);
      annotation.subTypes = state.entity[annotationId].subTypes.map(
        subTypeId => state.annotationSubType[subTypeId]
      );

      return annotation;
    })
};
